export const RELATIONSHIPS = [
  "Friend",
  "Mother",
  "Father",
  "Sister",
  "Brother",
  "Grandmother",
  "Grandfather",
  "Cousin",
  "Aunt",
  "Uncle",
  "Niece",
  "Nephew",
  "Spouse",
  "Partner",
  "Girlfriend",
  "Boyfriend",
  "Husband",
  "Wife",
  "Ex-husband",
  "Ex-wife",
  "Stepmother",
  "Stepfather",
  "Stepsister",
  "Stepbrother",
  "Stepson",
  "Stepdaughter",
  "Best friend",
  "Roommate",
  "Colleague",
  "Boss",
  "Employee",
  "Mentor",
  "Mentee",
  "Teacher",
  "Student",
  "Neighbor",
  "Classmate",
  "Teammate",
  "Doctor",
  "Patient",
  "Therapist",
  "Client",
  "Lawyer",
  "Investor",
  "Business partner",
  "Supplier",
  "Customer",
  "Vendor",
  "Contractor",
  "Subcontractor",
  "Consultant",
  "Advisor",
  "Agent",
  "Manager",
];
