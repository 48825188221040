export const HOBBIES = [
  "Acting",
  "Action Figures",
  "Adventure parks",
  "Aerobics",
  "Aeromodeling",
  "Air hockey",
  "Air sports",
  "Airbrushing",
  "Aircraft Spotting",
  "Aircraft",
  "Airsoft",
  "All-Terrain vehicles",
  "American football",
  "Amusement parks",
  "Angling/Fly fishing",
  "Animal care",
  "Animal fancy",
  "Animals",
  "Antiques",
  "Aquariums",
  "Arcade Games",
  "Archaeology",
  "Archery",
  "Architecture",
  "Aromatherapy",
  "Art",
  "Artwork",
  "Astrology",
  "Astronomy",
  "Audiophilia",
  "Australian Football League",
  "Auto racing",
  "Autographs",
  "Automotive work/repair",
  "Backgammon",
  "Backpacking",
  "Badge Collecting",
  "Badminton",
  "Baking",
  "Ballet",
  "Ballooning",
  "Base Jumping",
  "Baseball",
  "Basketball",
  "Baton Twirling",
  "Beach Volleyball",
  "Beach",
  "Beachcombing",
  "Beadwork",
  "Beatboxing",
  "Beekeeping",
  "Bell Ringing",
  "Belly Dancing",
  "Bicycle Polo",
  "Bicycling",
  "Billiards",
  "Bird watching",
  "Birding",
  "Birdwatching",
  "Blacksmithing",
  "Blogging",
  "BMX",
  "Board games",
  "Board sports",
  "BoardGames",
  "Boating",
  "Bodybuilding",
  "Bonsai Trees",
  "Book collecting",
  "Bookbinding",
  "Boomerangs",
  "Bowling",
  "Boxing",
  "Brazilian jiu-jitsu",
  "Breakdancing",
  "Brewing Beer",
  "Bridge Building",
  "Bridge",
  "Bringing Food To The Disabled",
  "Building Dollhouses",
  "Bus spotting",
  "Butterfly Watching",
  "Button Collecting",
  "Cake Decorating",
  "Calligraphy",
  "Camping",
  "Candle making",
  "Canoeing",
  "Car Racing",
  "Card collecting",
  "Cartooning",
  "Casino Gambling",
  "Cave Diving",
  "Ceramics",
  "Cheerleading",
  "Chess",
  "Church",
  "Cigar Smoking",
  "Climbing",
  "Cloud Watching",
  "Coin Collecting",
  "Collecting Antiques",
  "Collecting Artwork",
  "Collecting Hats",
  "Collecting Music Albums",
  "Collecting RPM Records",
  "Collecting Sports Cards",
  "Collecting Swords",
  "Collecting",
  "Color guard",
  "Coloring",
  "Comic book collecting",
  "Compose Music",
  "Computer activities",
  "Computer programming",
  "Conworlding",
  "Cooking",
  "Cooperative games",
  "Cosplay",
  "Couponing",
  "Courses",
  "Crafts",
  "Creative writing",
  "Cricket",
  "Crochet",
  "Cross-Stitch",
  "Crossword Puzzles",
  "Cryptography",
  "Curling",
  "Cycling",
  "Dancing",
  "Darts",
  "Debate",
  "Deck building games",
  "Deltiology",
  "Diecast Collectibles",
  "Digital arts",
  "Digital Photography",
  "Disc golf",
  "Diving",
  "Do it yourself",
  "Dodgeball",
  "Dog sport",
  "Dogs",
  "Dolls",
  "Dominoes",
  "Dowsing",
  "Drama",
  "Drawing",
  "Driving",
  "Dumpster Diving",
  "DVD",
  "Eating out",
  "Educational Courses",
  "Electronics",
  "Element collecting",
  "Embroidery",
  "Entertaining",
  "Equestrianism",
  "Exercise",
  "Exhibition drill",
  "Falconry",
  "Fast cars",
  "Felting",
  "Fencing",
  "Field hockey",
  "Field",
  "Figure skating",
  "Fire Poi",
  "Fishing",
  "Fishkeeping",
  "Flag Football",
  "Floorball",
  "Floral Arrangements",
  "Flower arranging",
  "Flower collecting and pressing",
  "Fly Tying",
  "Flying",
  "Footbag",
  "Football",
  "Foraging",
  "Foreign language learning",
  "Fossil hunting",
  "Four Wheeling",
  "Free diving",
  "Freshwater Aquariums",
  "Frisbee Golf",
  "Gambling",
  "Games",
  "Gaming",
  "Garage Saleing",
  "Gardening",
  "Genealogy",
  "Geocaching",
  "Geology",
  "Ghost hunting",
  "Glassblowing",
  "Glowsticking",
  "Gnoming",
  "Going to movies",
  "Golf",
  "Golfing",
  "Gongoozling",
  "Graffiti",
  "Grip Strength",
  "Guitar",
  "Gun Collecting",
  "Gunsmithing",
  "Gymnastics",
  "Gyotaku",
  "Handball",
  "Handwriting Analysis",
  "Hang gliding",
  "Herping",
  "Hiking",
  "Home Brewing",
  "Home Repair",
  "Home Theater",
  "Homebrewing",
  "Hooping",
  "Horse riding",
  "Hot air ballooning",
  "Hula Hooping",
  "Hunting",
  "Ice hockey",
  "Ice skating",
  "Iceskating",
  "Illusion",
  "Impersonations",
  "Inline skating",
  "Insects",
  "Internet",
  "Inventing",
  "Jet Engines",
  "Jewelry Making",
  "Jigsaw Puzzles",
  "Jogging",
  "Judo",
  "Juggling",
  "Jukskei",
  "Jump Roping",
  "Kabaddi",
  "Kart racing",
  "Kayaking",
  "Keep A Journal",
  "Kitchen Chemistry",
  "Kite Boarding",
  "Kite flying",
  "Kites",
  "Kitesurfing",
  "Knapping",
  "Knife making",
  "Knife throwing",
  "Knitting",
  "Knotting",
  "Lacemaking",
  "Lacrosse",
  "Lapidary",
  "LARPing",
  "Laser tag",
  "Lasers",
  "Lawn Darts",
  "Learning An Instrument",
  "Learning To Pilot A Plane",
  "Leather crafting",
  "Leathercrafting",
  "Leaves",
  "Lego building",
  "Legos",
  "Letterboxing",
  "Listening to music",
  "Locksport",
  "Machining",
  "Macrame",
  "Magic",
  "Magician",
  "Mahjong",
  "Making Model Cars",
  "Marbles",
  "Marksmanship",
  "Martial arts",
  "Matchstick Modeling",
  "Meditation",
  "Metal detecting",
  "Meteorology",
  "Microscopy",
  "Mineral collecting",
  "Model aircraft",
  "Model building",
  "Model Railroading",
  "Model Rockets",
  "Modeling Ships",
  "Models",
  "Motor sports",
  "Motorcycles",
  "Mountain Biking",
  "Mountain Climbing",
  "Mountaineering",
  "Movie and movie memorabilia collecting",
  "Mushroom hunting/Mycology",
  "Musical Instruments",
  "Nail Art",
  "Needlepoint",
  "Netball",
  "Nordic skating",
  "Orienteering",
  "Origami",
  "Owning An Antique Car",
  "Paintball",
  "Painting",
  "Papermache",
  "Papermaking",
  "Parachuting",
  "Paragliding or Power Paragliding",
  "Parkour",
  "People Watching",
  "pets",
  "Photography",
  "Piano",
  "Pigeon racing",
  "Pinochle",
  "Pipe Smoking",
  "Planking",
  "Playing music",
  "Playing musical instruments",
  "Playing soccer",
  "Playing team sports",
  "Poker",
  "Pole Dancing",
  "Polo",
  "Pottery",
  "Powerboking",
  "Protesting",
  "Puppetry",
  "Puzzles",
  "Pyrotechnics",
  "Quilting",
  "R/C Boats",
  "R/C Cars",
  "R/C Helicopters",
  "R/C Planes",
  "Racing Pigeons",
  "Racquetball",
  "Radio-controlled car racing",
  "Radio",
  "Rafting",
  "Railfans",
  "Rappelling",
  "Rapping",
  "Reading To The Elderly",
  "Reading",
  "Record collecting",
  "Relaxing",
  "Renaissance Faire",
  "Renting movies",
  "Rescuing Abused",
  "Robotics",
  "Rock balancing",
  "Rock climbing",
  "Rock Collecting",
  "Rockets",
  "Rocking AIDS Babies",
  "Rocks",
  "Roleplaying",
  "Roller derby",
  "Roller skating",
  "Rugby league football",
  "Rugby",
  "Running",
  "Sailing",
  "Saltwater Aquariums",
  "Sand art",
  "Sand Castles",
  "Scrapbooking",
  "Scubadiving",
  "Scuba",
  "Sculling or Rowing",
  "Sculpting",
  "Sculpture",
  "Seaglass collecting",
  "Seashell collecting",
  "Seashells",
  "Self Defense",
  "Self-Improvement Courses",
  "Self-Improvement",
  "Service",
  "Sewing",
  "Shark Fishing",
  "Shoes",
  "Shooting guns",
  "Shooting sport",
  "Shooting",
  "Shopping for latest trends",
  "Shopping",
  "Shortwave listening",
  "Show Choir",
  "Sightseeing",
  "Singing games",
  "Singing In Choir",
  "Singing Lessons",
  "Singing",
  "Singing/composing music",
  "Skateboarding",
  "Skating",
  "Skeet Shooting",
  "Sketching",
  "Skiing",
  "Skill games",
  "Skimboarding",
  "Skincare Regime",
  "Skydiving",
  "Sky running",
  "Skype",
  "Slack Lining",
  "Slacklining",
  "Sledding",
  "Sleeping",
  "Slingshots",
  "Slot car racing",
  "Snake",
  "Snooker",
  "Snorkeling",
  "Snow Biking",
  "Snowboarding",
  "Snowmobiles",
  "Snowshoeing",
  "Soapmaking",
  "Soccer",
  "Social Networking",
  "Socializing",
  "Soft Rock",
  "Softball",
  "Space Exploration",
  "Speed Cubing",
  "Speed skating",
  "Spelunkering",
  "Spending time with family/kids",
  "Spider",
  "Spinning",
  "Sport fishing",
  "Sporting dog field trials",
  "Sporting interests",
  "Sports Cards",
  "Sports memorabilia",
  "Sports",
  "Squash",
  "Stained Glass",
  "Stamp Collecting",
  "Stamps",
  "Stand-up comedy",
  "Stargazing",
  "Stereo",
  "Stocks",
  "Stone collecting",
  "Stone skipping",
  "Storm Chasing",
  "Storytelling",
  "Strategy games",
  "Street games",
  "Street Racing",
  "String Figures",
  "Sudoku Puzzles",
  "Sudoku",
  "Sun tanning",
  "Sunbathing",
  "Surf Fishing",
  "Surfing",
  "Survey date",
  "Survival",
  "Sweepstake",
  "Sweepstakes",
  "Swimming pool",
  "Swimming",
  "Table football",
  "Table tennis",
  "Tabletop games",
  "Taekwondo",
  "Tai Chi",
  "Tango Dance",
  "Target shooting",
  "Tarot card reading",
  "Tarot",
  "Tatting",
  "Taxidermy",
  "Tea Tasting",
  "Team Games",
  "Telescopes",
  "Ten Pin Bowling",
  "Tennis",
  "Tesla Coils",
  "Tetris",
  "Textiles",
  "Texting",
  "Theater Sports",
  "Theater/Performing Arts",
  "Theatre",
  "thematic games",
  "Third person shooter games",
  "Thriller",
  "Tie dying",
  "Tile-based games",
  "Timeshare",
  "Tombstone Rubbing",
  "Tool Collecting",
  "Touch football",
  "Tour skating",
  "Toy Collecting",
  "Toys",
  "Track &amp",
  "Train Collecting",
  "Train Spotting",
  "Trainspotting",
  "Trap",
  "Travel games",
  "Travel",
  "Traveling and exploration",
  "Traveling",
  "Treasure Hunting",
  "Tree climbing",
  "Trekkie",
  "Trekking",
  "Triathlon",
  "Turtle",
  "Tutoring Children",
  "Tutoring",
  "TV watching",
  "Types of Recreation",
  "Ultimate Frisbee",
  "Urban exploration",
  "US Travel",
  "Vacation cruises",
  "Vacations",
  "Vehicle restoration",
  "Veterans",
  "Video game collecting",
  "Video gaming",
  "Video",
  "Videophilia",
  "Vintage Books",
  "Vintage car",
  "Vintage cars",
  "Vintage clothing",
  "Violin",
  "Vitamin supplements",
  "Volleyball",
  "Volunteer",
  "Volunteering",
  "Walking and Hiking",
  "Walking",
  "Walking/health",
  "Wall Art",
  "Want further mailings",
  "Wargames",
  "Warhammer",
  "Warships",
  "Water Polo",
  "Water Skiing",
  "Water sports",
  "Watercolor Paintings",
  "Weather Forecasting",
  "Weather Watcher",
  "Weaving",
  "Web surfing",
  "Websites",
  "Weight training",
  "Weightlifting",
  "White Water Rafting",
  "Whitewater rafting",
  "Wildlife safari   Snorkeling A",
  "Wildlife",
  "Wind Surfing",
  "Windsurfing Kayaking",
  "Windsurfing",
  "Wine Appreciation",
  "Wine Making",
  "Wine tasting",
  "Wines",
  "Wingsuit flying",
  "Wood Carving",
  "Wood Working",
  "Woodworking",
  "Word games",
  "Working In A Food Pantry",
  "Working on cars",
  "World news or politics",
  "World Record Breaking",
  "Worldbuilding",
  "Worship Team",
  "Wrestling",
  "Writing Music",
  "Writing Songs",
  "Writing",
  "Yachting",
  "Yo-yoing",
  "Yoga",
  "Youth Band",
  "Youth Group",
  "Ziplining",
  "Zumba",
];
