export function Logo() {
  return (
    <svg
      width="48"
      height="45"
      viewBox="0 0 64 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="60" rx="10" fill="url(#paint0_linear_16_1169)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1932 12.9903C19.9932 12.6241 19.2127 12.844 18.5622 13.5936C18.2629 13.9385 18.1721 14.1975 18.1415 14.3724C18.1095 14.5553 18.1155 14.8106 18.2421 15.1805C18.5212 15.9958 19.2836 17.065 20.6035 18.2899C23.1994 20.6991 27.0895 22.9297 29.924 24.2378L28.052 28.6474C25.0088 27.243 20.6318 24.772 17.5284 21.8918C15.9986 20.4721 14.5532 18.7496 13.8863 16.8016C13.5399 15.7898 13.3882 14.6705 13.5915 13.5077C13.7961 12.3371 14.3334 11.2793 15.1326 10.3584C17.2281 7.9435 19.9887 7.59824 22.4923 8.36226C24.8665 9.08678 27.1291 10.8067 28.9782 12.7756C30.8514 14.7703 32.4795 17.2103 33.5142 19.6455C34.5087 21.9863 35.1405 24.7685 34.3615 27.205L29.9738 25.6801C30.2066 24.9521 30.1157 23.5526 29.2855 21.5986C28.4954 19.739 27.1968 17.7713 25.6736 16.1493C24.1262 14.5016 22.5225 13.396 21.1932 12.9903Z"
        fill="url(#paint1_linear_16_1169)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.0467 14.0681C44.3065 14.1311 44.6026 14.2822 44.9291 14.6724C45.3368 15.1597 45.3765 15.5257 45.1948 16.076C44.9497 16.8182 44.2761 17.8029 43.0924 18.9422C40.8383 21.1114 37.4889 23.1316 34.9593 24.3685C35.0552 23.7559 35.2583 23.0161 35.609 22.1603C36.3201 20.4246 37.4887 18.5894 38.8561 17.0795C40.2503 15.5399 41.6719 14.5349 42.8158 14.1729C43.3622 14 43.7622 13.9991 44.0467 14.0681ZM34.898 24.9336C34.8743 25.3574 34.9113 25.6967 34.9881 25.9456L30.585 27.4213C29.8718 25.1082 30.4503 22.476 31.3558 20.266C32.2984 17.9653 33.7825 15.6579 35.4933 13.7688C37.1774 11.9093 39.2598 10.2588 41.4726 9.55856C42.6045 9.20037 43.846 9.0685 45.0945 9.37127C46.3677 9.68004 47.4961 10.4052 48.414 11.5021C49.9973 13.3942 50.2437 15.5987 49.5679 17.6455C48.9555 19.5004 47.6282 21.1393 46.2293 22.4856C43.3947 25.2136 39.4043 27.5485 36.633 28.8748L34.898 24.9336Z"
        fill="url(#paint2_linear_16_1169)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9063 23.0668H41.25C46.3586 23.0668 50.5 27.3848 50.5 32.7112V42.3556C50.5 47.6821 46.3586 52 41.25 52H23.9063C18.7977 52 14.6563 47.6821 14.6563 42.3556V32.7112C14.6563 27.3848 18.7977 23.0668 23.9063 23.0668ZM23.9063 29.0946C21.9906 29.0946 20.4376 30.7138 20.4376 32.7112V42.3556C20.4376 44.353 21.9906 45.9723 23.9063 45.9723H41.25C43.1658 45.9723 44.7188 44.353 44.7188 42.3556V32.7112C44.7188 30.7138 43.1658 29.0946 41.25 29.0946H23.9063Z"
        fill="url(#paint3_linear_16_1169)"
      />
      <rect
        x="27.375"
        y="33.3165"
        width="2.89062"
        height="5.42497"
        rx="1"
        fill="white"
      />
      <rect
        x="35.4687"
        y="33.9193"
        width="2.31249"
        height="4.21942"
        rx="1.15625"
        fill="white"
      />
      <rect
        x="35.4687"
        y="33.9193"
        width="2.31249"
        height="4.21942"
        rx="1.15625"
        fill="white"
      />
      <rect
        x="35.4687"
        y="33.9193"
        width="2.31249"
        height="4.21942"
        rx="1.15625"
        fill="white"
      />
      <rect
        x="35.4687"
        y="33.9193"
        width="2.31249"
        height="4.21942"
        rx="1.15625"
        fill="white"
      />
      <rect
        x="35.4687"
        y="33.3165"
        width="2.89062"
        height="5.42497"
        rx="1"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_16_1169"
          x1="56.7423"
          y1="4.33333"
          x2="13.3415"
          y2="54.5557"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A61CE8" />
          <stop offset="1" stopColor="#5913EF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_16_1169"
          x1="17.836"
          y1="20.3544"
          x2="48.5791"
          y2="44.1148"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stopColor="white" />
          <stop offset="0.925218" stopColor="#ECEBF3" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_16_1169"
          x1="17.836"
          y1="20.3544"
          x2="48.5791"
          y2="44.1148"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stopColor="white" />
          <stop offset="0.925218" stopColor="#ECEBF3" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_16_1169"
          x1="17.836"
          y1="20.3544"
          x2="48.5791"
          y2="44.1148"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stopColor="white" />
          <stop offset="0.925218" stopColor="#ECEBF3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
